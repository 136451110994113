interface AdProps {
  readonly class?: string;
  readonly slot: string;
}

import styles from '~/components/Ad.module.scss';

export default function Ad(props: AdProps) {
  return (
    <div classList={{ [styles.wrapper]: true, [props.class ?? '']: true }}>
      <iframe
        src={`${import.meta.env.VITE_API_ENDPOINT}/ad/~${props.slot}`}
        title="Anúncio"
        sandbox="allow-forms allow-popups allow-popups-to-escape-sandbox allow-scripts allow-top-navigation-by-user-activation"
      />
    </div>
  );
}
