import { Show, type JSX } from 'solid-js';
import { Dynamic } from 'solid-js/web';

import styles from '~/components/SkeletonPlaceholder.module.scss';

interface SkeletonPlaceholderProps {
  readonly inline?: boolean;
  readonly size?: JSX.CSSProperties['inline-size'];
  readonly children?: JSX.Element;
  readonly class?: string;
  readonly classList?: Record<string, boolean | undefined>;
}

export default function SkeletonPlaceholder(props: SkeletonPlaceholderProps) {
  const tag = () => (props.inline ? 'span' : 'div');

  return (
    <Dynamic
      component={tag()}
      class={props.class}
      classList={{ ...props.classList, [styles.placeholder]: true }}
      style={{ 'inline-size': props.size }}
      aria-hidden="true"
    >
      <Show when={props.children}>
        <span>{props.children}</span>
      </Show>
    </Dynamic>
  );
}
